import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const Autocomplete = () => (
    <GooglePlacesAutocomplete 
    	apiKey="" 
    	  autocompletionRequest={{
		    componentRestrictions: {
		    country: ['gb'],
		}
  	}}
    />
);

export default Autocomplete;