import React from "react"
import PropTypes from "prop-types"
import { Fragment, Component } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
  UserIcon,
  SearchIcon
} from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class NavbarNew extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      logged_in: this.props.logged_in,
      action: this.props.action
    };
  }

  render () {
  	const logged_in = this.state.logged_in;
    const action = this.state.action;
        
      const navigation = [
        { name: 'Find a therapy room', href: '/search/therapy-rooms', current: false },
        { name: 'Create a listing', href: '/rooms/new', current: false }
    ]
    
      const userNavigation = [
        { name: 'My Listings', href: '/account/listings/rooms' },
        { name: 'My Messages', href: '/messages' },
        { name: 'My Subscriptions', href: '/subscriptions' },
        { name: 'My Account', href: '/businesses/edit' },
        { name: 'Sign out', href: '/business/logout' },
      ]

      const loggedOutUserNavigation = [
        { name: 'Login', href: '/businesses/sign_in' }
      ]
      
    return (
        <>
        {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
        <Popover
          as="header"
          className={({ open }) =>
            classNames(
              open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
              'bg-white shadow-sm lg:static lg:overflow-y-visible'
            )
          }
        >
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
                  <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-4 py-4">
                    <div className="flex flex-shrink-0 items-center">
                      <a href="/">
                        <img
                          className="block h-8 w-auto"
                          src="https://cdn.wellnessrooms.co.uk/assets/images/WellnessRooms_Logo_vector_new.svg"
                          alt="Therapy Portal Logo"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-4">
                    <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                      <div className="w-full hidden sm:block">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        {action == "show" &&
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                          <form action="/search/therapy-rooms" method="GET">
                          <input
                            id="location"
                            name="location"
                            className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:leading-6 mt-1 block w-full rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search using city or postal code"
                            type="search"
                          />
                            </form>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                    {/* Mobile menu button */}
                    <Popover.Button className="-mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <div className="block h-6 w-6" aria-hidden="true"><XIcon className="h-6 w-6" aria-hidden="true" /></div>
                      ) : (
                        <div className="block h-6 w-6" aria-hidden="true"><MenuIcon className="h-6 w-6" aria-hidden="true" /></div>
                      )}
                    </Popover.Button>
                  </div>

                  <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4 py-4">
                    {/* Profile dropdown */}

                    {logged_in == true &&
                    <div>
                    <Menu as="div" className="relative ml-5 flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <UserIcon className="h-6 w-6" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    </div>
                    }
                    
                    {logged_in == false &&
                    <div>
                    <a
                      href="/businesses/sign_in"
                      className="ml-6 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black hover:text-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    >
                    <UserIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      My Account
                    </a>
                    <a
                      href="/page/advertise-therapy-rooms"
                      className="ml-6 inline-flex items-center rounded-md bg-white shadow-sm ring-1 ring-inset ring-gray-300 px-3 py-2 text-sm font-semibold text-grey-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-600"
                    >
                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                    </svg>
                      Create a listing
                    </a>
                    </div>
                    }

                    {logged_in == true &&
                    <a
                      href="/rooms/new"
                      className="ml-6 inline-flex items-center rounded-md bg-white shadow-sm ring-1 ring-inset ring-gray-300 px-3 py-2 text-sm font-semibold text-grey-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-600"
                    >
                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                    </svg>
                      Create a listing
                    </a>
                    }

                  </div>
                </div>
              </div>
  
              <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                <div className="mx-auto max-w-3xl space-y-1 px-2 pb-3 pt-2 sm:px-4">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                        'block rounded-md py-2 px-3 text-base font-medium'
                      )}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                  </div>
                  {logged_in == true &&
                  <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                    {userNavigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  }
                  {logged_in == false &&
                    <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                    {loggedOutUserNavigation.map((item) => (
                        <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                        {item.name}
                        </a>
                    ))}
                    </div>
                  }
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
      </>
          );
  }
}

export default NavbarNew